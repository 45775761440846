import React from 'react';
import {Hero} from '../components/Hero';
import How from '../components/How';
import {Why} from '../components/Why';

export const Home = () => {
  return (
    <>
      <Hero />
      <Why />
      <How />
    </>
  );
};
