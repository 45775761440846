import React from 'react';
import {Footer} from '../components/Footer';
import {Header} from '../components/Header';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Privacy} from '../pages/Privacy';
import {Home} from '../pages/Home';
import {Terms} from '../pages/Terms';

export const Layout = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};
