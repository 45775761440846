import React from 'react';
import {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import ReactPlayer from 'react-player';

export const VideoModal = ({modalActive, setModalActive}) => {
  return (
    <Transition.Root show={modalActive} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setModalActive}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div>
                  <ReactPlayer
                    url="https://stream.mux.com/uA7peEmS79FtVb86g01lImMPMwIo6SXCTxS5A4GSw3tA.m3u8"
                    muted={true}
                    controls={false}
                    playing={true}
                    loop={true}
                    width="100%"
                    height="auto"
                    className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
