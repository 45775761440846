import React from 'react';
import {BanknotesIcon, CalendarIcon, ShoppingBagIcon, GiftIcon} from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Sponsor Revenue',
    description: 'Marketing sandbox, with crypto incentives, loyalty, and CRM built into the Spaces experience.',
    icon: BanknotesIcon,
  },
  {
    name: 'Event Venue',
    description: 'Crypto loves IRL. Be the event hub and central meetup point for Web3 in your city.',
    icon: CalendarIcon,
  },
  {
    name: 'Merch Sales',
    description: 'SpacesDAO members get new Solana products at wholesale. Plus, fulfill local online orders.',
    icon: ShoppingBagIcon,
  },
  {
    name: 'Partner Bounties',
    description:
      'SpacesDAO has partnered with top companies in crypto. Partner bounties pay you to introduce new customers to them, create content, or fulfill other IRL missions.',
    icon: GiftIcon,
  },
];

export const Why = () => {
  return (
    <div className="bg-primary text-[#fff]">
      <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24">
        <h2 className="text-4xl font-bold tracking-tight text-white">
          Build Solana IRL with Monetization Opportunities
        </h2>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name}>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-md bg-white bg-opacity-10">
                  <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                <p className="mt-2 text-base text-indigo-200">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
