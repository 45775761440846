import React from 'react';
import hero from '../images/hero.jpg';
import {GlobeAltIcon, RocketLaunchIcon, WrenchScrewdriverIcon} from '@heroicons/react/24/outline';
import ReactPlayer from 'react-player';

const video = `${new URL('../images/hero.mp4', import.meta.url)}`;

export const Hero = () => {
  return (
    <>
      <div className="mx-auto mt-16 max-w-7xl px-4 sm:mt-16 sm:px-6">
        <div className="text-center">
          <h1 className="mb-16 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl md:text-7xl md:leading-[4.4rem]">
            <span className="">Solana IRL. Build it. </span>
            <span className="text-primary">Own it.</span>
          </h1>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex flex-col" aria-hidden="true">
          <div className="flex-1" />
          <div className="w-full flex-1" />
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6">
          <ReactPlayer
            url="https://stream.mux.com/eVZXZnWheMC6RM8Zayx4EqZ02NoVBiTeq3jeiUF00DHlo.m3u8"
            muted={true}
            controls={false}
            playing={true}
            loop={true}
            playsinline={true}
            width="100%"
            height="auto"
            className="relative rounded-lg shadow-lg overflow-hidden"
          />
        </div>
      </div>
      <div className="max-w-7xl mx-auto relative overflow-hidden bg-white pt-16 pb-32">
        <div className="relative">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
              <div>
                <div className="">
                  <h2 className="text-4xl font-bold leading-10 tracking-tight text-gray-900 mb-8">
                    Join the world’s first on-chain retail co-op
                  </h2>

                  <p className="mt-4 text-lg text-gray-500">
                    SpacesDAO is dedicated to helping independent owners and communities set up Solana Spaces in their
                    own city.
                  </p>
                </div>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 text-lg">
                <ul>
                  <div className="relative flex items-center mb-6">
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-gray-100 text-primary">
                      <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <dd className="mt-2 ml-16 text-gray-500">
                      Bring Solana Spaces to your hometown. Build a retail store, a kiosk, or an&nbsp;Embassy.
                    </dd>
                  </div>
                  <div className="relative flex items-center  mb-6">
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-gray-100 text-primary">
                      <RocketLaunchIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <dd className="mt-2 ml-16 text-gray-500">
                      Become a SpacesDAO member and join a community of other SpacesDAO members on a mission to build
                      the future of retail, and grow Solana&nbsp;IRL.
                    </dd>
                  </div>
                  <div className="relative flex items-center">
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-gray-100 text-primary">
                      <WrenchScrewdriverIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <dd className="mt-2 ml-16 text-gray-500">
                      SpacesDAO members enjoy access to a full range of resources to build, including architectural
                      plans, fixture specs, software, capital, and monetization&nbsp;tools.
                    </dd>
                  </div>
                </ul>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="-mr-48 px-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0 flex items-center max-w-full">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0"
                  src={hero}
                  alt="Inbox user interface"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
