import React, {Fragment, useState} from 'react';
import {Popover, Transition} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import logo from '../images/logo_spacesdao_black.png';
import {Link} from 'react-router-dom';
import {ContactModal} from './ContactModal';

export const Header = () => {
  const [modalActive, setModalActive] = useState(false);

  const handleModalClick = (e) => {
    e.preventDefault();
    setModalActive(true);
  };

  return (
    <>
      <Popover className="sticky top-0 z-50 bg-white">
        <div className="flex items-center justify-between px-4 py-6 sm:px-6 md:space-x-10 bg-gray-50 drop-shadow">
          <div>
            <Link to="/" className="flex">
              <span className="sr-only">SpacesDAO</span>
              <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex md:items-center md:justify-between">
            <div className="flex items-center md:ml-12">
              {/* <a href="https://nox.solanaspaces.com" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Sign in
            </a> */}
              <a
                href="https://t.co/VJnnXf0nqs"
                className="ml-8 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white bg-primary shadow-sm"
              >
                Join now
              </a>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <a
                    href="https://t.co/VJnnXf0nqs"
                    className="flex items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:text-primary-50"
                  >
                    Join now
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      {modalActive && <ContactModal setModalActive={setModalActive} modalActive={modalActive} />}
    </>
  );
};
