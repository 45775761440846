# **Privacy Policy**

Effective November 4, 2022.

This Privacy Policy describes how Sol Stores, Inc. and our subsidiaries and corporate affiliates (collectively, the " **Company** ," " **we**", " **us**" or " **our**") handle personal information that we collect through the following channels and as otherwise described in this Privacy Policy:

- ur websites and other digital properties that link to this Privacy Policy (collectively, the " **Site**");
- ur brick-and-mortar retail stores (if and when applicable) (together with the Site, the " **Service**");
- ur marketing activities; and
- ur social media accounts and platforms.

## Personal information we collect

Information you provide to us. Personal information you may provide to us through the Service or otherwise may include:

- **Contact data** , such as your first and last name, email address, billing and mailing addresses, and phone number.
- **Communications** that we exchange, including when you contact us with questions, feedback, or otherwise.
- **Research data** that you provide when you agree to participate in our tutorials, surveys or research activities, such as your survey responses.
- **Transactional data,** such asthe informationneeded to complete your orders on or through the Service (including name, credit card information, bank account number, cryptocurrency wallet address, billing and shipping information), and your purchase history.
- **Marketing data** , such as your preferences for receiving communications about products sold through our Service, services, events, promotions and publications, and details about how you engage with our communications.
- **Content** that you post or upload to areas of the Service visible to other users, such as product reviews or comments.
- **Other information** that we may collect which is not specifically listed here, which we will use as described in this Privacy Policy or as otherwise disclosed at the time of collection.

**Third party sources.** We may combine personal information we receive from you with personal information we obtain from other sources, such as:

- **Publicly-available sources** , such as social media platforms.
- **Business partners** , such as our brand partners who may sell their products and/or services through the Service, our joint marketing partners and our event co-sponsors.

**Automatic data collection.** We, our service providers, and our business partners may automatically log information about you, your computer or mobile device, and your interactions over time with our Service and other sites and online services, such as:

- **Device data** ,such as your computer or mobile device operating system type and version number, manufacturer and model, browser type, screen resolution, IP address, IP address, unique identifiers, the website you visited before browsing to our website, general location information (such as city, state or geographic area) and precise geolocation (when you give us permission to collect it in your device's settings).

- **Online activity data** , such as pages or screens you viewed, how long you spent on a page or screen, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access.
- **In-store sensor data,** such as information collected about visitors to our stores from video cameras, smartphone detectors, Wi-Fi networks, and other devices.
- **Security camera footage** from our stores.

**Cookies and similar technologies**. Like many online services, we use the following technologies:

- **Cookies** , which are text files that websites store on a visitor's device to uniquely identify the visitor's browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns, and facilitating online advertising.
- **Locally stored objects** , which are used on websites for purposes similar to cookies but allow storage of a larger amount of data.

**Information about others.** Users of the Service have the opportunity to share contact information of others with us. Please do not do so unless you have their permission.

## How we use your personal information

We use your personal information for the following purposes or as otherwise described at the time we collect it:

**Service delivery.** We may use your personal information to:

- provide, operate and improve the Service and our business;
- fulfill the orders you place through the Service;
- communicate with you about the Service, including by sending announcements, updates, security alerts, and support and administrative messages;

- understand your needs and interests, and personalize your experience with the Service and our communications; and
- provide support for the Service, respond to your requests, questions and feedback.
- allow us and our partners ("Partners") to deliver such benefits and information that we or they may offer to our members, such as responses to your offers, digital assets (e.g. a proof of attendance protocol NFT or marketing NFT) delivered to your cryptocurrency wallet address or physical item (e.g. baseball cap) delivered to your provided mailing address.

**Research and development.** We may use your personal information for research and development purposes, including to analyze and improve the Service and our business. As part of these activities, we may create aggregated, de-identified or other anonymous data from personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve the Service and promote our business.

**Marketing and advertising.** We and our third party advertising partners, if any, may collect and use your personal information for marketing and advertising purposes:

- Direct marketing. We may send you Company-related or other direct marketing communications as permitted by law, including by NFT, email and mail. You may opt-out of our marketing communications as described in the [Opt-out_of_marketing](#49x2ik5) section below.
- Testimonials and feedback. With their consent, we may publish testimonials and comments from users and identify them by their name or city.

**Compliance and protection.** We may use your personal information to:

- comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities;
- protect our, your or others' rights, privacy, safety, security or property (including by making and defending legal claims and security monitoring in our stores);
- audit our internal processes for compliance with legal and contractual requirements and internal policies;
- enforce the terms and conditions that govern the Service; and
- prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal activity, including cyberattacks and identity theft.

## How we share your personal information

We may share your personal information with the following parties and as otherwise described in this Privacy Policy or at the time of collection:

**Subsidiaries and Affiliates.** Our subsidiaries and corporate affiliates for purposes consistent with this Privacy Policy.

**Service providers.** Companies and individuals that provide services on our behalf or help us operate the Service or our business (such as IT, hosting, customer relationship management and support, payment processing, print and mail fulfillment, order fulfillment and shipping, data management, email delivery, marketing, research, website analytics, in-store sensor analytics, event management services, promotions, sweepstakes, contests, tutorials and surveys).

**Business partners.** Business partners, including event co-sponsors, companies with whom we jointly-market products and services, and brands and retailers that market and sell their products in our stores and on our Site, who may collect your information directly through our Service or with whom we may share your information as described to you when it is collected. Our business partners' use of your personal information is governed by their own privacy policies.

**Loyalty Program Partners.** Loyalty Program Partners, with whom we may share your cryptocurrency wallet address and/or mailing address in order to allow for your participation in their loyalty programs, including the delivery of loyalty benefit items. Our Loyalty Program Partners' use of your personal information is governed by their own privacy policies.

**Other users and the public.** Other parties who can view your product reviews, comments or other content on areas of the Service accessible to other users and the public, where it can be copied and retained even after you modify or delete it.

**Professional advisors.** Professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.

**Authorities and others.** Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or appropriate for the [compliance and operations purposes](#_z337ya) described above.

**Business transferees.** Relevant participants in business transactions (or potential transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of, or equity interests in, the Company or our affiliates (including, in connection with a bankruptcy or similar proceedings).

## Your choices

You have the following choices with respect to your personal information.

**Opt-out of marketing communications.** You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email, or by [emailing](#3tbugp1) us at marketing@spacesdao.org. You may continue to receive service-related and other non-marketing emails.

**Cookies.** Most browsers let you remove and/or stop accepting cookies from the websites you visit. To do this, follow the instructions in your browser's settings. Many browsers accept cookies by default until you change your settings. If you do not accept cookies, however, you may not be able to use all functionality of the Service and our Site may not work properly. For more information about cookies, including how to see what cookies have been set on your browser and how to manage and delete them, visit [www.allaboutcookies.org](http://www.allaboutcookies.org/). We may use Google Analytics to help us understand user activity and patters on the Site. You can learn more about Google Analytics cookies at [https://developers.google.com/analytics/resources/concepts/gaConceptsCookies](https://developers.google.com/ANALYTICS/RESOURCES/CONCEPTS/GACONCEPTSCOOKIES) and about how Google protects your data at [http://www.google.com/analytics/learn/privacy.html](http://www.google.com/analytics/learn/privacy.html). You can prevent the use of Google Analytics relating to your use of our Site by downloading and installing a browser plugin available at [https://tools.google.com/dlpage/gaoptout?hl=en](https://tools.google.com/DLPAGE/GAOPTOUT?HL=EN). Your web browser may provide functionality to clear locally stored objects in your browser storage.

**Declining to provide information.** We need to collect personal information to provide certain Services. If you do not provide the information requested, we may not be able to provide those Services.

**Third party platforms.** If you choose to connect to the Service through your social media account such as Facebook, you may be able to use your settings in your account with that platform to limit the information we receive from it. If you revoke our ability to access information from a third party platform, that choice will not apply to information that we have already received from that third party.

## Other sites and services

The Service may contain links to websites, mobile applications, and other online services operated by third parties. These links are not an endorsement of, or representation that we are affiliated with, any third party. In addition, our content may be included on web pages or in mobile applications or other online services that are not associated with us. We do not control websites, mobile applications or online services operated by third parties, and we are not responsible for their actions.

## Security

We employ a number of technical, organizational and physical safeguards designed to protect the personal information we collect. However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal information.

## International data transfer

We are headquartered in the United States and may use service providers that operate in other countries. Your personal information may be transferred to the United States or other locations where privacy laws may not be as protective as those in your state, province, or country.

## Children

The Service is not intended for use by children under 16 years of age. If we learn that we have collected personal information from a child under 16 without the consent of the child's parent or guardian as required by law, we will delete it.

## Changes to this Privacy Policy

We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by email to the email address you provided to the website and/or by updating the date of this Privacy Policy at the top of this page and posting it on the Service. Any modifications to this Privacy Policy will be effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all cases, your continued use of the Service after the posting of any modified Privacy Policy indicates your acceptance of the modified Privacy Policy.

## How to contact us

You can reach us by email at [privacy@spacesdao.org](mailto:privacy@spacesdao.org) and by mail at SpacesDAO Global Cooperative, Attention: Administrator, 548 Market Street PMB 45477, San Francisco, CA 94104.
