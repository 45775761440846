import React, {useEffect} from 'react';
import Markdown from '../markdown/terms.mdx';

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="relative overflow-hidden bg-white py-16 font-[DiaMono] px-2">
      <div className="prose prose-lg prose-a:text-primary mx-auto text-gray-500 px-4 sm:px-0">
        <Markdown />
      </div>
    </div>
  );
};
