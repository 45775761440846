import React, {useState} from 'react';
import {Fragment} from 'react';
import isEmail from 'validator/lib/isEmail';
import {Dialog, Transition} from '@headlessui/react';

export const ContactModal = ({modalActive, setModalActive}) => {
  const [formData, setFormData] = useState({} as any);
  const [message, setMessage] = useState('');
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (complete) {
      return;
    }
    if (!formData.email || !isEmail(formData.email)) {
      return setMessage('This email is invalid.');
    }
    if (!formData.location) {
      return setMessage('Please enter a location.');
    }

    setMessage('');

    const pairs = Object.entries(formData)
      .map(([key, value]) => ({key, value}))
      .filter(({value}) => value);
    pairs.push({key: 'internal_type', value: 'dao_contact_form'});

    const intake = {
      type: 'lead',
      pairs,
    };

    setLoading(true);

    try {
      const res = await fetch(`${process.env.GATSBY_NOX_BASE_URL}/api/dotcom/intakes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          intake,
        }),
      });

      // console.log('res', res)

      if (!res.ok) {
        throw new Error('');
      }

      setComplete(true);
      setMessage("Thanks! We'll reach out shortly");
    } catch (e) {
      console.log('err', e);
      setMessage('Sorry, please try again in a few minutes.');
    }
    setLoading(false);
  };

  console.log(formData);

  return (
    <Transition.Root show={modalActive} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setModalActive}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                  <div className="w-full max-w-md space-y-8">
                    <div>
                      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                        Want to open a Spaces?
                      </h2>
                      <p className="mt-2 text-center text-md text-gray-600">
                        Fill out this brief form and we'll contact you soon to chat.
                      </p>
                    </div>
                    <form onSubmit={handleSubmit} className="mt-8 space-y-6" action="#" method="POST">
                      <div className="rounded-md">
                        <div className="mb-6">
                          <label htmlFor="email" className="block text-md font-medium text-gray-700">
                            Email
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="block w-full rounded-md border-gray-300 pr-10 text-gray-900 placeholder-gray-300 focus:border-primary focus:outline-none focus:ring-primary-50 sm:text-md"
                              placeholder="example@solanaspaces.com"
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                        <div className="mb-6">
                          <label htmlFor="location" className="block text-md font-medium text-gray-700">
                            Potential location
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <input
                              type="text"
                              name="location"
                              id="location"
                              className="block w-full rounded-md border-gray-300 pr-10 text-gray-900 placeholder-gray-300 focus:border-primary focus:outline-none focus:ring-primary-50 sm:text-md"
                              placeholder="Lisbon"
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                        <div className="mb-6">
                          <label htmlFor="twitter" className="block text-md font-medium text-gray-700">
                            Twitter
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <input
                              type="text"
                              name="twitter"
                              id="twitter"
                              className="block w-full rounded-md border-gray-300 pr-10 text-gray-900 placeholder-gray-300 focus:border-primary focus:outline-none focus:ring-primary-50 sm:text-md"
                              placeholder="@spaces_DAO (optional)"
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                        <div className="mb-6">
                          <label htmlFor="telegram" className="block text-md font-medium text-gray-700">
                            Telegram
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <input
                              type="text"
                              name="telegram"
                              id="telegram"
                              className="block w-full rounded-md border-gray-300 pr-10 text-gray-900 placeholder-gray-300 focus:border-primary focus:outline-none focus:ring-primary-50 sm:text-md"
                              placeholder="@spacesDAO (optional)"
                              onChange={handleOnChange}
                            />
                          </div>
                        </div>
                      </div>
                      <p
                        className={complete ? 'mt-2 text-sm text-primary' : 'mt-2 text-sm text-red-600'}
                        id="name-error"
                      >
                        {message}
                      </p>
                      <div>
                        <button
                          type="submit"
                          className="group relative flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
