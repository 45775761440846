import React from 'react';
import {App} from './App';
import './index.css';
import './global-fonts.scss';
import {createRoot} from 'react-dom/client';

const epochMs = parseInt(process.env.REACT_APP_HOLD_EPOCHMS || '0');
const now = Date.now();
const ttl = now - epochMs;
const container = document.getElementById('app');
if (ttl > 0) {
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript
  root.render(<App />);
} else {
  container!.innerHTML = 'coming soon';
}
