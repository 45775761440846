import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {ContactModal} from './ContactModal';

const navigation = {
  social: [
    {name: 'Terms of Service', href: '/terms'},
    {name: 'Privacy Policy', href: '/privacy'},
    {name: 'Twitter', href: 'https://twitter.com/Spaces_DAO'},
  ],
};

export const Footer = () => {
  const [modalActive, setModalActive] = useState(false);

  const handleModalClick = (e) => {
    e.preventDefault();
    setModalActive(true);
  };

  return (
    <>
      <div className="bg-primary-50">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-24 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
            <span className="block">Ready to open a location?</span>
            <span className="block text-primary">Join now and be the first in your&nbsp;area.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md">
              <a
                href="https://t.co/VJnnXf0nqs"
                className="w-[200px] text-lg inline-flex items-center justify-center rounded-md border border-transparent bg-primary px-5 py-3 font-medium text-[#fff] hover:bg-primary"
              >
                Join now
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-white">
        <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex justify-center space-x-6">
            {navigation.social.map((item) =>
              item.name === 'Twitter' ? (
                <a href={item.href} title={item.name} className="text-gray-400 hover:text-gray-500">
                  {item.name}
                </a>
              ) : (
                <Link key={item.name} to={item.href} className="text-gray-400 hover:text-gray-500">
                  <span>{item.name}</span>
                </Link>
              )
            )}
          </div>
          <p className="mt-8 text-center text-sm text-gray-400">
            &copy; 2022 SpacesDAO&nbsp;&nbsp;&nbsp;All rights reserved.
          </p>
        </div>
      </footer>
      {modalActive && <ContactModal setModalActive={setModalActive} modalActive={modalActive} />}
    </>
  );
};
