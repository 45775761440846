import React, {useState} from 'react';
import {GlobeAltIcon, BanknotesIcon, EnvelopeOpenIcon} from '@heroicons/react/24/outline';
import globe from '../images/globe.png';
import logo from '../images/logo_f.png';
import {VideoModal} from './VideoModal';

export default function Example() {
  const [modalActive, setModalActive] = useState(false);

  return (
    <>
      <div className="overflow-hidden bg-gray-50 py-8 lg:py-16">
        <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative">
            <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              How do I become a SpacesDAO member?
            </h2>
          </div>

          <div className="relative mt-12 lg:mt-16 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="relative">
              <dl className="space-y-10">
                <div className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-primary">
                      <BanknotesIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">Apply for Access</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Fill out an application and prove your ability to raise capital and build. If approved, you’ll
                    receive access to SpacesDAO&nbsp;resources.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-primary">
                      <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                      Hold a{' '}
                      <span onClick={() => setModalActive(true)} className="text-primary underline cursor-pointer">
                        GeoNFT
                      </span>
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    Certain applicants may receive GeoNFTs at no cost. Select locations will be available through
                    auctions.
                  </dd>
                </div>
                <div className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-primary">
                      <EnvelopeOpenIcon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                      Redeem your GeoNFT and Open your Space
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    A SpacesDAO GeoNFT can be redeemed by an approved applicant for location exclusivity. Once you've
                    opened your Space, you'll become a member of&nbsp;SpacesDAO.
                  </dd>
                </div>
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
              <img src={globe} className="relative mx-auto w-[75%] h-full" alt="Globe stylized in the solana colors." />
            </div>
          </div>

          <div className="relative max-w-7xl mx-auto mt-12 sm:mt-16 lg:mt-24 text-center border-t border-gray-300 sm:pt-12">
            <div className="relative mt-10 lg:mt-0 max-w-[300px] mx-auto  mb-2" aria-hidden="true">
              <img src={logo} className="relative mx-auto w-[100%] h-full" alt="Globe stylized in the solana colors." />
            </div>

            <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Supported by the Solana Foundation
            </h3>
            <p className="mt-3 text-lg text-gray-500 max-w-4xl mx-auto">
              Thanks to generous funding and support from the Solana Foundation, everything that powers Solana Spaces is
              available to SpacesDAO members, with a mission to grow Solana&nbsp;globally.
            </p>
          </div>
        </div>
      </div>
      {modalActive && <VideoModal modalActive={modalActive} setModalActive={setModalActive} />}
    </>
  );
}
